<template>
  <div class="d-flex justify-content-between">
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT © {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href=""
          target="_blank"
          >New X Coins</b-link>
      </span>
    </p>
    <p style="margin-top: 0.25rem" class="d-flex">
      <span class="d-none d-md-block">{{ $t("Versão") }}</span> <span class="d-block d-md-none">v</span> <span class="text-primary" style="margin-left: 0.3rem">{{ appVersion }}</span>
    </p>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { version } from "../../../package";

export default {
  data() {
    return {
      appVersion: version,
    };
  },
  components: {
    BLink,
  },
};
</script>