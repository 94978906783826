<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="
        d-flex
        bookmark-wrapper
        align-items-center
        flex-grow-1        
        font-weight-bolder
        ml-1 ml-lg-0
      " style="font-size: 1.2rem">
      <!-- <div>
        <small>{{ $t("Saldo disponível") }}</small>
        <div>
          {{
            moneyFormat(returnUser.balance)
          }}
        </div>
      </div> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button variant="outline-primary" size="sm" class="ml-2"
      @click="() => (openWallet = true)">{{ $t("Abrir Carteira") }}</b-button>
      <!-- <Locale class="d-none d-lg-block" /> -->
      <Locale2 class="d-block" />
      <!-- <dark-Toggler /> -->
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 limitString" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary :title="returnUser.fullname" variant="outline-primary">
              {{ returnUser.fullname }}
            </p>
            <span class="user-status">@{{ returnUser.username }}</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src="avatar" class="badge-minimal" badge-variant="success"
            :text="avatarText(returnUser.fullname)" />
        </template>

        <b-dropdown-item to="/account-setting" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("Perfil") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("Sair") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <!-- modal deposit -->
    <!-- <modal-user-deposit :openModal="openModalDeposit" @changeVariable="() => (openModalDeposit = false)" /> -->
    <modal-wallet :openModal="openWallet" @changeVariable="() => (openWallet = false)" />
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  VBTooltip,
  BFormRadio
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import Locale from "@/layouts/components/Locale.vue";
import Locale2 from "@/layouts/components/Locale2.vue";
import DarkToggler from "@/layouts/components/DarkToggler.vue";
import { mapGetters, mapActions } from "vuex";
import ModalUserDeposit from "./UserDeposit.vue";
import axios from "axios";
import ModalWallet from "./UserWallet.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormRadio,

    // Navbar Components
    DarkToggler,
    Locale,
    Locale2,

    ModalUserDeposit,

    ModalWallet
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      avatar: null,
      openModalDeposit: false,
      pollingInterval: null,
      openWallet: false,
    };
  },
  computed: mapGetters(["returnUser"]),
  mounted() {
    this.loadingDataProfile();
  },
  watch: {
    returnUser: function (oldVal, newVal) {
      this.loadingDataProfile();
    },
  },
  methods: {
    ...mapActions(["userLogout"]),
    loadingDataProfile() {
      if (this.returnUser.roles) this.$ability.update(this.returnUser.roles);
      if (this.returnUser.avatar)
        this.avatar = this.returnUser?.avatar
          ? `${process.env.VUE_APP_API_URL}/avatar/${this.returnUser.avatar}`
          : null;
    },
    logout() {
      this.userLogout().then(() => {
        this.$router.push("/login");
      });
    },
  },
  setup() {
    avatarText;

    return {
      avatarText,
    };
  },
};
</script>

<style scoped>
.limitString {
  white-space: nowrap;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
