export default [
  {
    title: "Administrator",
    icon: "SettingsIcon",
    children: [
      {
        title: "General view",
        route: "administrator-dashboard",
        icon: "GridIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Depósitos em USDT",
        route: "crypto-deposits-list2",
        icon: "DollarSignIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      // {
      //   title: "Crypto Withdrawal",
      //   route: "crypto-withdrawal-list",
      //   icon: "ArrowUpCircleIcon",
      //   action: "read",
      //   resource: "admin_dashboard",
      // },
      {
        title: "Relatório de vendas",
        route: "crypto-deposits-list",
        icon: "FileTextIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Relatório de bônus",
        route: "admin-bonus-report",
        icon: "GiftIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Relatório de binário",
        route: "admin-binary-report",
        icon: "Share2Icon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Users list",
        route: "administrator-users-list",
        icon: "UsersIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Sponsorship",
        route: "add-remove-balance",
        icon: "UserPlusIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Robot config",
        route: "administrator-robot-config",
        icon: "CpuIcon",
        action: "read",
        resource: "admin_dashboard",
      },
      {
        title: "Tickets list",
        route: "customer-service-tickets-list",
        icon: "MailIcon",
        action: "read",
        resource: "admin_dashboard",
      },
    ],
  },
  {
    title: "Dashboard",
    route: "dashboard-my-results",
    icon: "PieChartIcon",
    action: "read",
    resource: "registered-user",
  },
  {
    title: "Licenses",
    route: "licenses",
    icon: "ClipboardIcon",
    action: "read",
    resource: "registered-user",
  },
  {
    title: "In operation",
    route: "in-operation",
    icon: "ActivityIcon",
    action: "read",
    resource: "registered-user",
  },
  {
    title: "Financeiro",
    icon: "CreditCardIcon",
    children: [
      {
        title: "Deposits",
        route: "wallet-my-deposits",
        action: "read",
        icon: "ArrowDownCircleIcon",
        resource: "registered-user",
      },
      // {
      //   title: "Withdrawals",
      //   route: "wallet-my-withdrawals",
      //   action: "read",
      //   icon: "ArrowUpCircleIcon",
      //   resource: "registered-user",
      // },
    ]
  },
  {
    title: "Affiliates",
    icon: "UsersIcon",
    children: [
      {
        title: "Affiliate dashboard",
        route: "affiliate-network-dashboard",
        icon: "BarChartIcon",
        action: "read",
        resource: "registered-user",
      },
      {
        title: "Lista de Afiliados",
        route: "affiliate-network-direct-references",
        icon: "UserCheckIcon",
        action: "read",
        resource: "registered-user",
      },
      {
        title: "Relatório de bônus",
        route: "affiliate-bonus-report",
        icon: "GiftIcon",
        action: "read",
        resource: "registered-user",
      },
      {
        title: "Binary Tree",
        route: "affiliate-network-binary-tree",
        icon: "Share2Icon",
        action: "read",
        resource: "registered-user",
      },
    ],
  },
  {
    title: "Material de apoio",
    icon: "FolderIcon",
    children: [
      {
        title: "Vídeos",
        route: "marketing-academy",
        icon: "VideoIcon",
        tagVariant: "light-info",
        action: "read",
        resource: "registered-user",
      },
      {
        title: "Imagens",
        route: "marketing-share",
        icon: "ImageIcon",
        tagVariant: "light-info",
        action: "read",
        resource: "registered-user",
      },
      {
        title: "Materiais em PDF",
        route: "marketing-support-material",
        icon: "FileIcon",
        tagVariant: "light-info",
        action: "read",
        resource: "registered-user",
      },
    ],
  },
  {
    title: "Support",
    route: "support",
    tag: "Ticket",
    tagVariant: "light-info",
    icon: "HelpCircleIcon",
    action: "read",
    resource: "registered-user",
  },
];
