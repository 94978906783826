<template>
  <div>
    <b-modal id="modal-wallet-navbar" :title="$i18n.t('Carteira')" centered hide-footer no-close-on-backdrop>
      <div v-if="!readWalletBalances">
        {{ $t('Carregando...') }}
      </div>
      <div v-else>
        <!-- <div>
          <h6>{{ $t("Rede") }}:</h6>
          <v-select v-model="selectNetwork" :options="networks" class="per-page-selector d-inline-block"
            style="width: 100%;" :disabled="true" />
        </div> -->
        <div class="mt-2">
          <h6 class="text-center">{{ $t("Endereço para depósitos") }}:</h6>
          <div class="d-flex justify-content-center align-items-center cursor-pointer" @click="copyAddress">
            <div class="text-primary" :style="{ fontSize: isMobile ? '11px' : '14px', fontWeight: 'bold' }">
              {{ returnUser.blockchain_address }}
            </div>
          </div>
        </div>

        <hr>

        <div class="mt-2">
          <div class="d-flex justify-content-center">
            <b-button class="" variant="outline-primary" size="sm"
              @click="() => token_action != 'transfer' ? token_action = 'transfer' : token_action = ''">
              {{ $t(`Enviar tokens`) }}
            </b-button>
            <b-button class="ml-1" variant="outline-info" size="sm"
              @click="() => token_action != 'swap' ? token_action = 'swap' : token_action = ''">
              {{ $t('Trocar tokens') }}
            </b-button>
            <b-button class="ml-1" variant="outline-warning" size="sm"
              @click="() => token_action != 'historic' ? token_action = 'historic' : token_action = ''">
              {{ $t('Histórico de transações') }}
            </b-button>
          </div>
        </div>

        <div class="mt-2">
          <div v-if="token_action === ''">
            <div class="mb-1 text-warning">
              <small style="font-weight:bold;">{{ $t(`Tokens da rede Binance Smart Chain (BSC) aceitos:`) }}</small>
            </div>
            <div class="my-1" v-for="token of readWalletBalances">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <div><b-img :src="token.iconUrl" style="width: 36px; border-radius: 50%;" /></div>
                  <div style="margin-left: 1rem;">
                    <div style="font-size: 1.1rem;">{{ token.tokenSymbol }}</div>
                    <div>{{ token.tokenName }}</div>
                  </div>
                </div>
                <div class="text-right">
                  <div v-if="token.balance">{{ token.balance && parseFloat(token.balance).toFixed(4) }} {{
                    token.tokenSymbol }}</div>
                  <div v-if="token.tokenUSDValue">{{ token.tokenUSDValue.toFixed(2) }} USD</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="token_action === 'transfer'" class="mt-2">
          <div class="text-center">
            <h5>{{ $t('Transfira seus tokens') }}</h5>
          </div>
          <div class="my-1">
            <h6>{{ $t("Selecione o ativo") }}:</h6>
            <select class="form-control" v-model="token_transfer">
              <option v-for="token in readWalletBalances" :value="token">{{ token.tokenName + ' - ' +
                parseFloat(token.balance).toFixed(4) + ' ' + token.tokenSymbol }}</option>
            </select>
          </div>

          <div class="my-1">
            <h6>{{ $t("Endereço para envio") }}:</h6>
            <input type="text" :placeholder="$t('Informe o endereço para envio')" class="form-control"
              name="icon-filter" v-model="sendAddress">
          </div>

          <div class="mt-2 px-1">
            <b-alert :show="!sendEmail" variant="warning" class="p-1">
              {{
                $t(`Para sua segurança, a transferência de tokens só será possível mediante a confirmação de um código
              enviado para seu e-mail.`)
              }}
              <div class="mt-1">
                <b-button size="sm" variant="danger" @click="sendCode">{{
                  $t(`Clique aqui para receber o código no e-mail`)
                }}</b-button>
              </div>
            </b-alert>
            <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert"
              @changeVariableAlert="(val) => (showAlert = val)" class="mb-1" />
            <b-form-group>
              <div class="my-1">
                <h6>{{ $t("Código de segurança") }}:</h6>
                <input type="text" :placeholder="$t('Digite o código de segurança')" class="form-control"
                  name="icon-filter" v-model="securityCode">
              </div>
            </b-form-group>
          </div>

          <div class="row my-1 align-items-center">
            <div class="col-12 col-md-8">
              <h6>{{ $t("Valor da transferência") }}:</h6>
              <input type="number" :placeholder="$t('Informe o valor')" class="form-control" name="icon-filter"
                v-model="transferValue">
            </div>

            <div class="d-flex justify-content-center col-12 col-md-4 mt-2">
              <b-button variant="primary" :disabled="btnWalletLoading" @click="transferToken"><span
                  v-if="!btnWalletLoading">{{
                    $t('Enviar') }}</span><span v-else>{{ $t('Aguarde...') }}</span></b-button>
            </div>
          </div>          
        </div>

        <div v-if="token_action === 'swap'" class="mt-2">
          <div class="text-center">
            <h5>{{ $t('Troque seus tokens') }}</h5>
          </div>

          <div class="my-1">
            <h6>{{ $t("Selecione o ativo que deseja enviar") }}:</h6>
            <select class="form-control" v-model="token_send">
              <option v-for="token in readWalletBalances" :value="token">{{ token.tokenName + ' - ' +
                parseFloat(token.balance).toFixed(4) + ' ' + token.tokenSymbol }}</option>
            </select>
          </div>

          <div class="my-1">
            <h6>{{ $t("Quantos tokens?") }}:</h6>
            <input type="number" :placeholder="$t('Informe o valor')" class="form-control" name="icon-filter"
              v-model="swap_value">
          </div>

          <div class="my-1">
            <h6>{{ $t("Selecione o ativo que deseja receber") }}:</h6>
            <select class="form-control" v-model="token_receive">
              <option v-for="token in readWalletBalances" :value="token">{{ token.tokenName + ' - ' +
                parseFloat(token.balance).toFixed(4) + ' ' + token.tokenSymbol }}</option>
            </select>
          </div>

          <div class="my-1 d-flex justify-content-center">
            <b-button variant="info" :disabled="btnWalletLoading" @click="swapTokens"><span v-if="!btnWalletLoading">{{
              $t('Trocar') }}</span><span v-else>{{ $t('Aguarde...') }}</span></b-button>
          </div>
        </div>

        <div v-if="token_action === 'historic'" class="mt-2">
          <div v-if=historicLoading>
            {{ $t('Carregando...') }}
          </div>
          <div v-else>
            <div class="text-center">
              <h5>{{ $t('Últimas atividades') }}</h5>
            </div>
            <div class="historic-container">
              <div v-for="data of historic">
                <small>{{ formatDate(data.createdAt) }}</small>
                <h6>{{ $t(data.title) }}</h6>
                <div v-if="data.value">{{ $t('Valor') }}: {{ moneyFormat(data.value) }}</div>
                <div v-if="data.details && data.details.clientName">{{ data.details.clientName }}</div>
                <a v-if="data.details && data.details.hash" :href="'https://bscscan.com/tx/' + data.details.hash"
                  target="_blank" rel="noopener noreferrer">
                  {{ $t('Visualizar na blockchain') }}
                </a>
                <hr>
              </div>
            </div>
          </div>
        </div>

        <div v-if="alertMsg" class="alert p-2" :class="`alert-${alertMsg.color}`" role="alert">
          {{ alertMsg.message }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, VBModal, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import { BFormRadioGroup, BFormGroup, BFormFile } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { debounce } from "debounce";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormRadioGroup,
    BFormGroup,
    BFormFile,
    vSelect
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    openModal: Boolean,
  },
  computed: mapGetters(["returnUser"]),
  watch: {
    openModal: function (newVal) {
      if (newVal == true) {
        this.$bvModal.show("modal-wallet-navbar");
      }
      this.$emit("changeVariable");
      this.readWalletBalances = null;
      this.walletInfo()
    },
    token_action: function (newVal) {
      if (newVal === 'historic') {
        this.historicLoading = true
        this.$store.dispatch("historic_wallet", this.limit)
          .then(async (response) => {
            this.historic = response.data.logUser
            this.historicLoading = false
          }).catch((error) => {
            // console.log(error);
          })
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  data() {
    return {
      sendEmail: false,
      colorAlert: "warning",
      showAlert: false,
      textAlert: "",
      securityCode: "",
      cryptoSelected: "BTC",
      cryptoOptions: [
        { text: "Bitcoin", value: "BTC" },
        { text: "USDT", value: "USDT.bep20" },
        { text: "BUSD", value: "BUSD.bep20" },
        { text: "BNB", value: "BNB.BSC" },
      ],
      createDeposit: {},
      depositValue: 0,
      depositValuePIX: 0,
      depositValueInBRL: 0,
      depositCrypto: "",
      payMethod: "crypto",
      file: null,
      urlImage: null,
      profileFile: null,
      loading: false,

      // WEB3
      selectNetwork: { label: 'Binance Smart Chain - Mainnet' },
      networks: [{ label: 'Binance Smart Chain - Mainnet' }],
      isHovered: false,
      readWalletBalances: null,
      token_transfer: null,
      sendAddress: null,
      transferValue: null,
      token_action: '',
      walletTokens: [],
      btnWalletLoading: false,
      token_send: null,
      token_receive: null,
      swap_value: null,
      isMobile: window.innerWidth <= 768,
      limit: 20,
      historic: [],
      historicLoading: false,
      alertMsg: null,
      walletInfoExecute: false,
    };
  },
  methods: {
    sendCode() {
      const data = {
        clientEmail: this.returnUser.email,
        clientName: this.returnUser.fullname,
      };
      this.$store.dispatch("sendSecurityCode", data).then((resp) => {
        this.sendEmail = true;
        this.colorAlert = "success";
        this.showAlert = true;
        this.textAlert = resp.message;
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna um índice baseado em zero, então adicionamos 1
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    swapTokens() {

      this.btnWalletLoading = true;

      const data = {
        token_send: this.token_send,
        token_receive: this.token_receive,
        swap_value: this.swap_value
      }

      this.$store.dispatch("swapTokens", data)
        .then(async (response) => {
          this.alertMsg = null;
          this.alertMsg = {
            message: response.message,
            color: 'success'
          }
          setTimeout(() => {
            this.alertMsg = null;
          }, 5000)
          this.walletInfo();
        }).catch((error) => {
          this.btnWalletLoading = false;
          this.alertMsg = null;
          this.alertMsg = {
            message: error.message,
            color: 'danger'
          }
          setTimeout(() => {
            this.alertMsg = null;
          }, 5000)
        })
    },
    transferToken() {

      this.btnWalletLoading = true;

      const data = {
        token_transfer: this.token_transfer,
        sendAddress: this.sendAddress,
        transferValue: this.transferValue,
        securityCode: this.securityCode
      }

      this.$store.dispatch("transferToken", data)
        .then(async (response) => {
          this.alertMsg = null;
          this.alertMsg = {
            message: response.message,
            color: 'success'
          }
          setTimeout(() => {
            this.alertMsg = null;
          }, 5000)
          this.walletInfo();
        }).catch((error) => {
          this.securityCode = "";
          this.btnWalletLoading = false;
          this.alertMsg = null;
          this.alertMsg = {
            message: error.message,
            color: 'danger'
          }
          setTimeout(() => {
            this.alertMsg = null;
          }, 5000)
        })

    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    copyAddress() {
      let copyText = this.returnUser.blockchain_address;
      navigator.clipboard.writeText(copyText);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "CopyIcon",
          variant: "primary",
          title: this.$t("Endereço da carteira copiado!"),
        },
      });
    },
    walletInfo() {
      if (!this.walletInfoExecute) {
        this.walletInfoExecute = true
        this.$store.dispatch("readWalletBalances", this.returnUser.blockchain_address).then((resp) => {
          this.readWalletBalances = resp.data
          this.walletInfoExecute = false
          this.token_transfer = null
          this.sendAddress = null
          this.transferValue = null
          this.token_action = ''
          this.walletTokens = []
          this.swap_value = null
          this.token_send = null
          this.token_receive = null
          this.btnWalletLoading = false
          this.securityCode = ""
          resp.data.map(e => {
            this.walletTokens.push({
              label: e.balance && e.tokenName + ' - ' + parseFloat(e.balance).toFixed(4) + ' ' + e.tokenSymbol,
              value: e
            })
          })
        });
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.historic-container {
  max-height: 400px;
  /* Altura máxima antes da barra de rolagem aparecer */
  overflow-y: auto;
  /* Habilita a rolagem vertical quando necessário */
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
