<template>
  <div>
    <!-- basic modal -->
    <b-modal
      id="modal-deposit-navbar"
      :title="$i18n.t('Fazer depósito')"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <div
        class="d-flex flex-column px-1"
        v-if="Object.keys(createDeposit).length === 0"
      >
        <div>
          Qual método de pagamento você deseja utilizar?
          <div class="d-flex">
            <b-button
              variant="outline-primary"
              block
              class="mt-1 mr-2"
              @click="payMethod = 'crypto'"
              >Criptomoeda</b-button
            >
            <b-button
              variant="outline-info"
              block
              class="mt-1 mr-2"
              @click="payMethod = 'pix'"
              >PIX</b-button
            >
          </div>
        </div>
        <div v-if="payMethod == 'crypto'" class="mt-2">
          <div>
            {{ $t("Digite o valor que você pretende depositar") }}:
          </div>
          <b-form-input
            id="allocation-value"
            v-model="depositValue"
            name="allocation-value"
            placeholder="R$ 0,00"
            class="my-2"
            type="number"
          />
          <div class="mt-1">Valor que será depositado: {{ moneyFormat(depositValue) }}</div>
          <b-form-group
            :label="
              $i18n.t(
                'Selecione a criptomoeda que deseja utilizar para o depósito:'
              )
            "
          >
            <b-form-radio-group
              v-model="cryptoSelected"
              :options="cryptoOptions"
              class="my-1"
              name="radio-inline"
            />
          </b-form-group>
          <b-button
            variant="primary"
            size="sm"
            @click="generateDeposit"
            :disabled="depositValue < 5"
            >{{
              $t(`Gerar
                  endereço para depósito`)
            }}</b-button
          >
        </div>
        <div v-if="payMethod == 'pix'" class="mt-2">
          <div>
            {{ $t("Digite o valor que você pretende depositar") }}:
          </div>
          <div class="my-2">
            <b-form-input
              id="allocation-value"
              v-model="depositValuePIX"
              name="allocation-value"
              placeholder="$0.00"
              type="number"
              :state="
                depositValuePIX == 0
                  ? null
                  : depositValuePIX < 5
                  ? false
                  : true
              "
            />
            <small
              class="text-danger"
              v-if="depositValuePIX < 5 && depositValuePIX != 0"
              >{{ $t("O valor de depósito mínimo é de R$ 5,00") }}
            </small>
            <div class="mt-1">
              {{ $t(`Você receberá de saldo`) }}
              <span class="text-info">{{
                moneyFormat(depositValuePIX)
              }}</span>
              <!-- ({{ $t("Taxa de depósito 2%") }}) -->
            </div>
          </div>
          <!-- <div>
            {{ $t("O valor que você precisa transferir via PIX é de") }}:
          </div>
          <h4 class="text-primary text-center mt-1">
            {{
              depositValueInBRL.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </h4> -->
          <div>{{ $t("Transfira para a chave PIX abaixo") }}:</div>
          <h4 class="text-primary text-center mt-1">34.256.090/0001-06</h4>
          <!-- <div class="d-flex justify-content-center my-1">
            <b-img
              fluid
              :src="require('@/assets/images/business_plan/qrcodepix.png')"
              alt="qrcode-pix"
              style="max-width: 16rem"
            />
          </div> -->
          <div>
            {{ $t("Agora, por favor, anexe o comprovante da transferência") }}:
          </div>
          <div class="d-flex flex-column justify-content-center mt-1">
            <b-button
              variant="primary"
              size="sm"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t("Carregar comprovante") }}
            </b-button>
            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png, .pdf"
              :hidden="true"
              plain
              @change="onFileChange"
            />
            <small :class="profileFile && 'text-warning'">
              {{
                profileFile && urlImage.length > 3
                  ? $t("Imagem selecionada: ") + profileFile.name
                  : $t("JPG, PNG ou PDF permitidos.")
              }}
            </small>
          </div>
          <div class="mt-1">
            {{ $t("Após seguir esse passo a passo, clique no botão abaixo") }}:
          </div>
          <alert
            :text="textAlert"
            :color="colorAlert"
            :showAlert="showAlert"
            @changeVariableAlert="(val) => (showAlert = val)"
          />
          <div class="d-flex justify-content-center mt-1">
            <b-button
              variant="info"
              @click="confirmDeposit"
              :disabled="depositValuePIX < 11 || !profileFile || loading"
              >{{ $t(`Enviar comprovante`) }}</b-button
            >
          </div>
        </div>
      </div>
      <div
        v-if="Object.keys(createDeposit).length !== 0"
        class="p-1 text-center"
      >
        <b-alert variant="success" show>
          <div class="alert-body">
            <span>{{
              $t(
                "Intensão de depósito criada com sucesso! Por favor, siga as seguintes instruções:"
              )
            }}</span>
          </div>
        </b-alert>
        <div>{{ $t("Envie exatamente este valor") }}:</div>
        <div
          class="d-flex flex-column my-1 cursor-pointer"
          @click="copyCryptoValue"
        >
          <strong style="font-size: 1.3rem">
            {{ createDeposit.amount }} {{ depositCrypto }}
          </strong>
          <small class="text-muted">{{
            $t("clique para copiar o valor")
          }}</small>
        </div>
        <div>{{ $t("Envie para a seguinte carteira") }}:</div>
        <div
          class="d-flex flex-column my-1 cursor-pointer"
          @click="copyCryptoAddress"
        >
          <strong style="font-size: 1.3rem">
            {{ createDeposit.address }}</strong
          >
          <small class="text-muted">{{
            $t("clique para copiar o endereço da carteira")
          }}</small>
        </div>
        <b-img
          :src="createDeposit.qrcode_url"
          fluid
          alt="QRCODE"
          class="mb-2"
        />
        <b-alert variant="warning" show>
          <div class="alert-body">
            <span>Após {{createDeposit.confirms_needed}} confirmações de rede, o valor equivalente a {{moneyFormat(depositValue)}} será creditado em seu saldo</span>
          </div>
        </b-alert>
        <!-- <b-button variant="outline-primary" size="sm">{{
          $t("Clique para verificar o status do seu depósito")
        }}</b-button> -->
        <hr class="my-1" />
        <b-button
          variant="primary"
          size="sm"
          @click="() => (createDeposit = {})"
          >{{
            $t(`Clique para gerar outra
        intensão de depósito`)
          }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, VBModal, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import Slider from "./Slider.vue";
import { mapGetters } from "vuex";
import { BFormRadioGroup, BFormGroup, BFormFile } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { debounce } from "debounce";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormRadioGroup,
    BFormGroup,
    BFormFile,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    openModal: Boolean,
  },
  computed: mapGetters(["returnUser"]),
  watch: {
    openModal: function (newVal) {
      if (newVal == true) {
        this.$bvModal.show("modal-deposit");
      }
      this.$emit("changeVariable");
    },
    depositValuePIX: debounce(function (newVal) {
      this.$store
        .dispatch("convertUSDtoURL", newVal)
        .then((resp) => {
          this.depositValueInBRL = this.depositValuePIX * resp.ask;
        })
        .catch((err) => {
          // console.log(err);
        });
    }, 1000),
  },
  data() {
    return {
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
      cryptoSelected: "BTC",
      cryptoOptions: [
        { text: "Bitcoin", value: "BTC" },
        { text: "USDT", value: "USDT.bep20" },
        { text: "BUSD", value: "BUSD.bep20" },
        { text: "BNB", value: "BNB.BSC" },
      ],
      createDeposit: {},
      depositValue: 0,
      depositValuePIX: 0,
      depositValueInBRL: 0,
      depositCrypto: "",
      payMethod: "",
      file: null,
      urlImage: null,
      profileFile: null,
      loading: false,
    };
  },
  methods: {
    confirmDeposit() {
      if (!this.loading) {
        this.loading = true;
        const data = {
          value: this.depositValuePIX,
          valueBRL: this.depositValueInBRL,
          proof: this.profileFile,
        };
        this.$store
          .dispatch("userCreateDepositPIX", data)
          .then((resp) => {
            this.depositValuePIX = 0;
            this.profileFile = null;
            this.urlImage = null;
            this.showAlert = true;
            this.textAlert = resp.message;
            this.colorAlert = "success";
            this.$emit("refreshListDeposits");
            setTimeout(() => {
              this.loading = false;
              this.showAlert = false;
              this.$bvModal.hide("modal-deposit");
            }, 3000);
          })
          .catch((err) => {
            this.showAlert = true;
            this.textAlert = err;
            this.colorAlert = "danger";
            setTimeout(() => {
              this.loading = false;
              this.showAlert = false;
            }, 5000);
          });
        this.$emit("refreshListDeposits");
      }
    },
    generateDeposit() {
      const data = {
        value: this.depositValue,
        crypto: this.cryptoSelected,
      };
      this.$store
        .dispatch("userCreateDeposit", data)
        .then((resp) => {
          this.createDeposit = resp.createDeposit;
          this.depositValue = resp.value;
          this.depositCrypto = resp.crypto;
          this.$emit("refreshListDeposits");
        })
        .catch((error) => {
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = error.message;
        });
    },
    copyCryptoValue() {
      let copyText = this.createDeposit.amount;
      navigator.clipboard.writeText(copyText);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "CoffeeIcon",
          variant: "info",
          title: copyText,
          text: this.$i18n.t(
            "esse valor foi copiado para a sua área de transferências"
          ),
        },
      });
    },
    copyCryptoAddress() {
      let copyText = this.createDeposit.address;
      navigator.clipboard.writeText(copyText);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "CoffeeIcon",
          variant: "info",
          title: copyText,
          text: this.$i18n.t(
            "esse endereço foi copiado para a sua área de transferências"
          ),
        },
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImage = URL.createObjectURL(file);
      this.profileFile = file;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
